import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    delay: Number
  }

  connect() {
    setTimeout(() => { this.destruct() }, this.delayValue)
  }

  destruct() {
    this.element.remove();
  }
}
