import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "answer", "placeholder" ]
  connect() {
    // this.element.textContent = "Hello World!"
  }
  reveal() {
    let i = 0;
    while (i < this.answerTargets.length) {
      this.answerTargets[i].style.display = "block";
      i++;
    }
    let j = 0;
    while (j < this.placeholderTargets.length) {
      this.placeholderTargets[j].style.display = "none";
      j++;
    }
  }
}
