// https://www.ananunesdasilva.com/posts/simple-dropdown-with-tailwindcss-and-stimulus
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleable" ]         

  toggle() {
    let i = 0;
    while (i < this.toggleableTargets.length) {
      this.toggleableTargets[i].classList.toggle('hidden')
      i++;
    }
  }  
}
